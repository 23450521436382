<template>
  <div class="view pa24">
    <div>
      选择状态：
      <el-radio
        class="mr10"
        style="margin: 0"
        v-for="(item, index) in radioArr"
        :key="index"
        v-model="radioValue"
        :label="item.value"
        border
        >{{ item.text }}</el-radio
      >
      <el-button type="primary" @click="search">搜索</el-button>
      <el-button
        type="primary"
        style="float: right"
        @click="$router.push({ path: '/demandPublishForm' })"
        >发布需求</el-button
      >
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          show-overflow-tooltip
        />
        <el-table-column prop="createdTime" align="center" label="创建时间" />
        <el-table-column prop="name" align="center" label="发布人" />
        <el-table-column prop="description" align="center" label="内容" show-overflow-tooltip/>
        <el-table-column prop="images" align="center" label="图片">
          <template slot-scope="scope">
            <el-image
              style="width: 36px; height: 36px"
              :src="scope.row.imagesList[0]"
              :preview-src-list="scope.row.imagesList"
            >
            </el-image>
            <span>x{{ scope.row.imagesList.length }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="note" align="center" label="备注" show-overflow-tooltip/>
        <el-table-column prop="auditing" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag>{{
              scope.row.auditing == 0
                ? "待审核"
                : scope.row.auditing == 1
                ? "已通过"
                : scope.row.auditing == 2
                ? "已驳回"
                : ""
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                $router.push({
                  path: `/demandPublishForm?squareId=${scope.row.squareId}`,
                })
              "
              >详情</el-button
            >
            <customPopconfirm
              v-if="scope.row.auditing == '0'"
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="online(scope.row)"
              icon="el-icon-info"
              icon-color="red"
              :title="
                '确认' + (scope.row.status == '0' ? '上线' : '下线') + '吗？'
              ">
                <el-button class="ml10" slot="reference" type="text">
                  {{ scope.row.status == "1" ? "下线" : "上线" }}
                </el-button>
            </customPopconfirm>
            <customPopconfirm
              v-if="scope.row.auditing == '0'"
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="updateDemandSquareStatus(scope.row)"
              icon="el-icon-info"
              icon-color="red"
              title="确认撤回吗？">
                <el-button type="text" class="ml10" slot="reference">
                  {{ scope.row.auditing == "0" && "撤回" }}
                </el-button>
            </customPopconfirm>
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="deleteDemandSquare(scope.row)"
              icon="el-icon-info"
              icon-color="red"
              title="确认删除吗？">
              <el-button
                class="ml10"
                slot="reference"
                type="text"
                style="color: #fd634e"
              >
                删除
              </el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  queryPageMyDemandSquare,
  deleteDemandSquare,
  updateDemandSquareStatus,
} from "@/api/addedServices";
export default {
  data() {
    return {
      radioValue: "",
      radioArr: [
        { text: "全部", value: "" },
        { text: "待审核", value: "0" },
        { text: "已驳回", value: "2" },
        { text: "已发布", value: "1" },
        { text: "被下架", value: "3" },
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  created() {
    this.queryPageMyDemandSquare();
  },
  methods: {
    /** */
    async queryPageMyDemandSquare() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        isDraft: "0",
        auditing: this.radioValue,
      };
      try {
        this.loading = true;
        const result = await queryPageMyDemandSquare(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        if (!list) {
          this.tableData = [];
          return;
        }
        list.forEach((item) => {
          item.imagesList = JSON.parse(item.images);
        });
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        //-console.log(error);
      }
    },
    handleSizeChange(val) {
      this.currentPage =1;
      this.pageSize = val;
      this.queryPageMyDemandSquare();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPageMyDemandSquare();
    },
    /**@method 搜索 */
    search() {
      this.currentPage=1;
      this.queryPageMyDemandSquare();
    },
    /**@method 删除 */
    async deleteDemandSquare(row) {
      try {
        await deleteDemandSquare(row);
        this.queryPageMyDemandSquare();
      } catch (error) {
        //-console.log(error);
      }
    },
    /**@method 撤回 */
    async updateDemandSquareStatus(row) {
      try {
        row.isDraft = true;
        await updateDemandSquareStatus(row);
        this.queryPageMyDemandSquare();
      } catch (error) {
        //-console.log(error);
      }
    },
    /**@method 上线 */
    async online(row) {
      if (row.status == "0" && row.auditing != 1) {
        this.$message({
          message: "审核通过的需求才能上架",
        });
        return;
      }
      row.status = row.status == "0" ? "1" : "0";
      row.isDraft = row.status == "0" ? true : false;
      try {
        await updateDemandSquareStatus(row);
        this.queryPageMyDemandSquare();
      } catch (error) {
        //-console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>